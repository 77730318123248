<style lang="scss">
  @import './PreLoginModal.scss';
</style>

<template>
  <div v-if="show_modal" class="pre-login-modal-container">
    <div class="modal-backdrop" v-on:click="toggleModal(false)"></div>
    <div class="modal">
      <div class="modal-header">
        <h1>{{getModalTitle}}</h1>
        <a v-on:click="toggleModal(false)" class="close-modal-btn">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0595 3.05994L18.9395 0.939941L10.9995 8.87994L3.05945 0.939941L0.939453 3.05994L8.87945 10.9999L0.939453 18.9399L3.05945 21.0599L10.9995 13.1199L18.9395 21.0599L21.0595 18.9399L13.1195 10.9999L21.0595 3.05994Z" fill="#DEDEDE"/>
          </svg>
        </a>
      </div>
      <div class="modal-body">
        <div class="icon-container">
          <svg version="1.0" viewBox="0 0 850.39 566.93" xmlns="http://www.w3.org/2000/svg">
            <ellipse transform="matrix(.7071 -.7071 .7071 .7071 -131.7 248.97)" cx="234.69" cy="283.46" rx="227.59" ry="227.59" fill="#f00"/>
            <polygon points="234.37 510.82 51.44 148.95 417.81 149.8"/>
            <path d="m139.91 212.63h3.18c7.11 0 13.32 1.06 13.32 9.84 0 8.48-6.81 9.54-13.62 9.54h-2.87v-19.38zm-40.56 82.94h40.56v-36.63h0.76l18.16 36.63h47.83l-24.82-37.08c-2.27-3.33-4.84-5.15-6.96-6.81v-0.91c15.44-5.15 22.1-12.41 22.1-29.67 0-28.76-21.79-39.65-47.53-39.65h-50.1v114.12z" fill="#fff"/>
            <polygon points="198.62 218.53 221.47 217.47 221.47 295.57 262.94 295.57 262.94 217.47 285.8 218.53 285.8 181.45 198.62 181.45" fill="#fff"/>
            <polygon points="292.28 295.57 368.56 295.57 368.56 258.95 333.75 258.95 333.75 181.45 292.28 181.45" fill="#fff"/>
            <polygon points="97.33 336.06 107.92 335.33 107.92 382.08 132.76 382.08 132.76 313.23 97.33 314.32" fill="#fff"/>
            <path d="m172.25 333.5c7.49 0 8.95 8.49 8.95 14.16 0 5.3-1.92 15.16-8.95 15.16s-8.95-9.86-8.95-15.16c0-5.67 1.46-14.16 8.95-14.16zm0-21.37c-20.82 0-32.88 15.34-32.88 34.89 0 20.64 11.6 36.16 32.88 36.16s32.88-15.52 32.88-36.16c0-19.55-12.06-34.89-32.88-34.89z" fill="#fff"/>
            <path d="m271.64 382.08v-21.18l-13.42 0.82-11.78 1.64v-0.73c12.15-3.74 24.2-12.33 24.2-26.21 0-15.98-15.16-24.29-29.5-24.29-20.73 0-29.68 9.04-31.51 29.13h24.47c0.18-4.2 0.82-8.68 6.12-8.68 3.11 0 5.66 2.37 5.66 5.48 0 6.12-10.87 15.8-15.16 19.63l-27.49 24.38h68.41z" fill="#fff"/>
            <path d="m293.08 360.26c-6.67 0-13.61 4.29-13.61 11.6 0 7.21 6.94 11.32 13.61 11.32s13.61-4.11 13.61-11.32c-0.01-7.31-6.94-11.6-13.61-11.6z" fill="#fff"/>
            <path d="m323.44 313.23-6.03 40.73c5.75-2.01 12.06-3.01 18.27-3.01 3.93 0 13.79 1.28 13.79 6.85 0 5.39-7.94 6.85-12.15 6.85-5.66 0-15.52-2.92-20.55-5.66l-2.74 18.63c6.48 3.2 20.27 5.57 27.49 5.57 14.61 0 32.97-7.94 32.97-24.84 0-13.15-10.96-21.55-23.56-21.55-3.47 0-9.41 0.64-11.69 3.65h-0.64c1.19-2.47 1.74-5.3 2.19-7.95h29.5v-19.27h-46.85z" fill="#fff"/>
            <polygon points="844 284.91 482.14 467.83 482.98 101.46" fill="#fff"/>
            <path d="m534.46 265.77h2.22c3.84 0 8.58 0.26 7.78 5.29-0.82 5.21-5.37 5.89-9.47 5.89h-2.31l1.78-11.18zm-29.95 46.77h22.53l3.07-19.37h11.95c13.06 0 22.55-8.19 24.63-21.34 2.53-15.96-7.35-23.64-22.37-23.64h-29.61l-10.2 64.35z" fill="#f00"/>
            <polygon points="566.63 312.54 609.64 312.54 612.92 291.89 593.29 291.89 600.21 248.19 576.82 248.19" fill="#f00"/>
            <path d="m644.7 289.24 7.38-19.63h0.85c-0.01 2.73-0.03 5.55 0.22 8.28l0.93 11.35h-9.38zm-6.6 15.28h17.67l0.86 8.02h24.75l-12.77-64.35h-25.86l-32.55 64.35h24.75l3.15-8.02z" fill="#f00"/>
            <path d="m681.9 248.19 16.65 36.61-4.39 27.74h22.87l4.39-27.74 27.82-36.61h-26.29l-6.31 10.75c-1.16 1.96-2.09 4.01-3.09 6.06h-0.68l-4.51-16.81h-26.46z" fill="#f00"/>
          </svg>
        </div>
        <div class="form-container text-center message" v-html="getModalText"></div>
        <div class="form-container text-center">
          <button type="button" v-on:click="doLogin()" class="primary">Accedi a MyPlay ora</button>
        </div>
        <div class="form-container text-center">
          <button type="button" v-on:click="toggleModal(false)" class="tertiary">Annulla</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreLoginModal',
  data: () => ({
    modal_title: 'RTL 102.5 Play',
    modal_text: `Per accedere a questo contenuto<br />e a tutta l'offerta esclusiva e gratuita, riservata alla community MyPlay, fai login o iscriviti`,
    override_modal_title: ``,
    override_modal_text: ``,
    show_modal : false,
  }),
  computed:{
    getModalTitle(){
      return (this.override_modal_title !== '') ? this.override_modal_title : this.modal_title;
    },
    getModalText(){
      return (this.override_modal_text !== '') ? this.override_modal_text : this.modal_text;
    }
  },
  methods: {
    toggleModal(newState = null){
      if (newState === null){
        newState = !this.show_modal;
      }
      if (!newState){
        this.override_modal_title = '';
        this.override_modal_text  = '';
      }
      this.show_modal = newState;
      
      this.$emit(newState ? 'modalShown' : 'modalHidden');
    },
    doLogin() {
      this.toggleModal(false);
      this.$emit('doLogin');
    },
    onKeyPress(e){
      const { keyCode = 0 } = e;
      if (keyCode === 27){ // esc
        this.toggleModal(false);
      }
    }
  },
  mounted(){
    document.addEventListener('keyup', this.onKeyPress);
  },
  destroyed() {
    document.addEventListener('keyup', this.onKeyPress);
  }
}
</script>